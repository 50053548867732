<template>
  <div class="toolbar-notification" :class="{ 'is-sticky': sticky }">
    <div
      class="notification-body"
      :style="{ 'background-color': backgroundColor }"
    >
      <!-- default notification -->
      <template v-if="!toolbarNotification.body">
        <div class="simple-notification">
          <img
            v-if="toolbarNotification.type === 'warning'"
            class="material-icons-round type-warning"
            :src="require('@/assets/icon-warning.svg')"
            alt=""
          />
          <img
            v-if="toolbarNotification.type === 'success'"
            class="material-icons-round type-success"
            :src="require('@/assets/icon-checked-circle-outline-green.svg')"
            alt=""
          />
          <div>{{ $t(toolbarNotification.text) }}</div>
          <i
            v-if="toolbarNotification.canClose"
            class="material-icons-round close-icon"
            @click="handleToolbarNotification({ isVisible: false })"
            >close</i
          >
        </div>
      </template>
      <!-- auth forms notification -->
      <template v-if="toolbarNotification.body === 'auth'">
        <div class="auth-notification">
          <span class="material-icons"> error_outline </span>
          <div class="notification-text">
            {{ $t("auth_form_user_exist") }}
          </div>
          <mem-button :btnType="'tertiary-light'" @click="goToSignIn">{{
            $t("sign_in")
          }}</mem-button>
        </div>
      </template>
      <!-- new version available -->
      <template v-if="toolbarNotification.body === 'appVersion'">
        <div class="auth-notification">
          <span class="material-icons"> error_outline </span>
          <div class="notification-text">
            {{ $t(toolbarNotification.text) }}
          </div>
          <mem-button :btnType="'tertiary-light'" @click="reloadApp">{{
            $t("update")
          }}</mem-button>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    "mem-button": () => import("./base/BaseButton.vue"),
  },
  props: {
    backgroundColor: {
      type: String,
      default: "#ffffff",
    },
    sticky: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["toolbarNotification"]),
  },
  methods: {
    ...mapActions(["handleToolbarNotification"]),
    goToSignIn() {
      this.$router.push({
        name: "formSignIn",
        query: this.$route.query,
        params: { email: this.toolbarNotification.data.email },
      });
      this.handleToolbarNotification({ isVisible: false });
    },
    reloadApp() {
      location.reload(true);
    },
  },
};
</script>
<style lang="scss" scoped>
.toolbar-notification {
  position: relative;
  z-index: $zindex-toolbar-notification;
  .notification-body {
    position: absolute;
    top: 0;
    width: 100%;
    color: #000000;
    // background-color: #ffffff;
    padding: 0 22px;

    font-size: 16px;
    line-height: 20px;
    font-weight: 500;

    .simple-notification {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 80px;
      .type-success,
      .type-warning {
        margin-right: 24px;
        font-size: 38px;
        width: 38px;
        height: 38px;
      }
      .close-icon {
        color: #000000;
        margin-left: 20px;
        font-size: 22px;
        cursor: pointer;
      }
    }
    .auth-notification {
      height: 80px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 499px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 16px 0 22px 0;
        height: auto;
      }
      .material-icons {
        color: #cc092f;
        font-size: 42px;
        @media screen and (max-width: 499px) {
          display: none;
        }
      }
      .notification-text {
        margin: 0 30px 0 10px;
        @media screen and (max-width: 499px) {
          margin: 0 0 20px 0;
          font-size: 14px;
          max-width: 266px;
        }
      }
      button {
        min-width: 154px;
        // margin: 0 20px;
        height: 44px;
        padding: 0 27px;
        @media screen and (max-width: 499px) {
          padding: 0 22px;
        }
      }
    }
  }
}

// only for checkout view, Need to add generic realization
.is-sticky {
  position: sticky;
  top: 70px;
  @media screen and (max-width: 1024px) {
    top: 0;
  }
}
</style>